/*
 * @Author: Gil Shulman 
 * @Date: 2023-01-23 
 * @Last Modified by: Mitas Ray 
 * @Last Modified time: 2025-01-07
 */
import React from 'react'
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import { tradeTypeDict, statesDict, purposeClassDict, ratingsDict } from './relatedVarDict'

import { useState } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead' 

import { yearMonthDayToMonthDayYear } from '../pricing'

import { MultiRangeSlider } from './multiRangeSlider'


function SimilarBonds({getAuthenticationToken, relatedSearchVal, setRelatedSearchVal, similarBondsRes, fetchRelated, isPricing, setIsRelatedLoading, isRelatedLoading, noRelatedBondsFound, setNoRelatedBondsFound, similarBondsSearchHasRun, setSimilarBondsSearchHasRun, searchValCusip, predictedYield, predictedPrice}) {
    
    const statesList = Object.entries(statesDict).map(([key, text]) => ({ 'id': key, 'label': text }))
    const purposeClassList = Object.entries(purposeClassDict).map(([key, text]) => ({ 'id': key, 'label': text }))
    const ratingList = Object.entries(ratingsDict).map(([key, text]) => ({ 'id': key, 'label': text }))

    const [minMaturityYear, setMinMaturityYear] = useState(2025)
    const [maxMaturityYear, setMaxMaturityYear] = useState(2125)

    const [minCoupon, setMinCoupon] = useState(0)
    const [maxCoupon, setMaxCoupon] = useState(10)

    const defaultCusip = '64971XQM3'
    const [searchCusip, setSearchCusip] = useState(defaultCusip)

    // const [minCalculationYear, setMinCalculationYear] = useState(2025)
    // const [maxCalculationYear, setMaxCalculationYear] = useState(2125)
    
    const [radioValue, setRadioValue] = useState('previous_day')
    const radios = [{ name: 'previous day', value: 'previous_day' },
                    { name: 'real-time', value: 'realtime' }]

    const [issuerChoiceValue, setIssuerChoiceValue] = useState('any_issuer')
    const issuerChoices = [{ name: 'any issuer', value: 'any_issuer' },
                           { name: 'same issuer', value: 'same_issuer' }]

    function setMaturityYear(minYear, maxYear) {
        setMinMaturityYear(minYear)
        setMaxMaturityYear(maxYear)
        relatedSearchVal.minMaturityYear = minYear
        relatedSearchVal.maxMaturityYear = maxYear
        // adding the below statement creates an infinite loop
        // setRelatedDict({'minMaturityYear': minYear, 
        //                 'maxMaturityYear': maxYear})
    }

    function setCoupon(minCoupon, maxCoupon) {
        setMinCoupon(minCoupon)
        setMaxCoupon(maxCoupon)
        relatedSearchVal.minCoupon = minCoupon
        relatedSearchVal.maxCoupon = maxCoupon
        // setRelatedDict({'minCoupon': minCoupon, 
        //                 'maxCoupon': maxCoupon})
    }

    // function setCalculationYear(minYear, maxYear) {
    //     setMinCalculationYear(minYear)
    //     setMaxCalculationYear(maxYear)
    //     // adding the below statement creates an infinite loop
    //     // setRelatedDict({'minCalculationYear': minYear, 
    //     //                 'maxCalculationYear': maxYear})
    // }

    function setRadioValueAndUpdateRelatedDict(radioValue) {
        setRadioValue(radioValue)
        setRelatedDict({'radio': radioValue})
    }

    function setIssuerChoiceValueAndUpdateRelatedDict(issuerChoiceValue) {
        setIssuerChoiceValue(issuerChoiceValue)
        setRelatedDict({'issuerChoice': issuerChoiceValue})
    }

    function set(name) {
        return function ({ target: { value } }) {
            setRelatedSearchVal(oldSearchValues => ({ ...oldSearchValues, [name]: value }))
        }
    }

    const setRelatedDict = (newValues) => {
        setRelatedSearchVal({...relatedSearchVal, ...newValues})
    }

    function findOptionById(options, id) {
        return options.reduce((foundOption, option) => {
            if (option.id == id) {    // if the comparator is changed to `===`, we get a TypeError
                foundOption = option
            }
            return foundOption
        }, undefined)
    }

    function setSearchCusipFunction() {
        if (typeof relatedSearchVal.cusip === 'undefined') { setSearchCusip(searchValCusip) }
        else { setSearchCusip(relatedSearchVal.cusip) }
    }
    
    const handleSubmit = event => {
        event.preventDefault()
        getAuthenticationToken()
        setIsRelatedLoading(true)
        setNoRelatedBondsFound(true)
        setSearchCusipFunction()

        if (relatedSearchVal.state === undefined) stateRef.current.clear()
        if (relatedSearchVal.rating === undefined) ratingRef.current.clear()
        if (relatedSearchVal.purposeClass === undefined) purposeClassRef.current.clear()
        
        fetchRelated(searchCusip, 
                     predictedYield, 
                     predictedPrice, 
                     minCoupon, 
                     maxCoupon, 
                     relatedSearchVal.state, 
                     relatedSearchVal.purposeClass, 
                    //  relatedSearchVal.purposeSubClass, 
                     relatedSearchVal.desc, 
                     relatedSearchVal.rating, 
                     minMaturityYear, 
                     maxMaturityYear, 
                    //  minCalculationYear, 
                    //  maxCalculationYear, 
                     relatedSearchVal.amount, 
                     relatedSearchVal.radio, 
                     relatedSearchVal.issuerChoice, 
                     true)

        return <><br /> <h5>Recent trades; click a row to expand trades for that CUSIP</h5></>
    }

    const [hiddenRows, setHiddenRows] = useState([])

    const [hover, setHover] = useState(false)

    const handleMouseEnter = (e) => {
        e.currentTarget.style.backgroundColor = '#d9edf7'
        setHover(true)
    }
    
    const handleMouseLeave = (e) => {
        e.currentTarget.style.backgroundColor = 'white'
        setHover(false)
    }
    
    const hoverStyle = {
      cursor: hover ? 'pointer' : 'default'
    }

    function handleClick(e) {
        setSimilarBondsSearchHasRun(true)
        const rowId = e.currentTarget.dataset.id
        setHiddenRows(prevHiddenRows => {
            const newHiddenRows = [...prevHiddenRows]
            const index = newHiddenRows.indexOf(rowId)
            if (index === -1) {
                newHiddenRows.push(rowId)
            } else {
                newHiddenRows.splice(index, 1)
            }
            return newHiddenRows
        })
    }

    function dispSingleTradeDetails(grouped, cusip) {       
        if (true) {    // need to check that similarBondsRes has data in it at some point
            return (
                grouped[cusip].map((result) => (
                <tr>
                    <td>{result.trade_datetime}</td>
                    {/* <td>{Math.round(result.spread, 0)}</td> */}
                    {/* <td>{Math.round(result.yield_curve_level * 100, 0)}</td> */}
                    <td>{result.yield}</td>
                    <td>{result.dollar_price}</td>
                    <td>{yearMonthDayToMonthDayYear(result.calc_date)}</td>
                    <td>{Math.floor(result.par_traded / 1000)}</td>
                    <td>{tradeTypeDict[result.trade_type]}</td>
                </tr>
                ))
            )
        }
    }

    function dispTradesByCusip(){
        // this gives an object with dates as keys
        // https://stackoverflow.com/questions/55272682/reduce-function-with-bracket-key
        function groupBy(objectArray, property) {
            if (objectArray !== undefined) {
                return objectArray.reduce(function(acc, obj) {
                    var key = obj[property]
                    if (!acc[key]) { acc[key] = [] }
                    acc[key].push(obj)
                    return acc
                }, {})
            }
        }

        function avg_yield(dailyTradeArr){
            const total_yield = dailyTradeArr.reduce((accumulator, trade) => accumulator + trade.yield, 0)
            return total_yield / dailyTradeArr.length
        }

        function avg_price(dailyTradeArr){
            const total_price = dailyTradeArr.reduce((accumulator, trade) => accumulator + trade.dollar_price, 0)
            return total_price / dailyTradeArr.length
        }

        // function avg_spread(dailyTradeArr){
        //     const total_spread = dailyTradeArr.reduce((accumulator, trade) => accumulator + trade.spread, 0)
        //     return total_spread / dailyTradeArr.length
        // }

        const grouped = groupBy(similarBondsRes, 'cusip')
        
        var summary_by_cusip = []

        for (var cusip in grouped) {
            var summary_by_cusip_entry = {}
            summary_by_cusip_entry['showDetails'] = false
            var rowId = 'k' + cusip
            
            summary_by_cusip_entry['id'] = rowId
            summary_by_cusip_entry['avg_yield'] = avg_yield(grouped[cusip])
            summary_by_cusip_entry['avg_price'] = avg_price(grouped[cusip])
            // summary_by_cusip_entry['avg_spread'] = avg_spread(grouped[cusip])
            summary_by_cusip_entry['cusip'] = cusip
            summary_by_cusip_entry['state'] = grouped[cusip][0].incorporated_state_code
            summary_by_cusip_entry['rating'] = grouped[cusip][0].rating
            summary_by_cusip_entry['security_description'] = grouped[cusip][0].security_description
            summary_by_cusip_entry['coupon'] = grouped[cusip][0].coupon
            summary_by_cusip_entry['maturity_date'] = grouped[cusip][0].maturity_date
            
            summary_by_cusip.push(summary_by_cusip_entry)
        }

        if (summary_by_cusip.length !== 0) {
            setNoRelatedBondsFound(false)
            return (summary_by_cusip.map((c) => (
                <>
                <tr key={c.id} data-id={c.id} onClick={handleClick} style={hoverStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <td>{c.cusip}</td>
                    <td>{statesDict[c.state]}</td>
                    <td>{ratingsDict[c.rating]}</td>
                    <td>{c.security_description}</td>
                    <td>{c.avg_yield.toFixed(3)}</td>
                    <td>{c.avg_price.toFixed(3)}</td>
                    {/* <td>{Math.round(c.avg_spread, 0)}</td> */}
                    <td>{+parseFloat(c.coupon).toFixed(3)}</td>
                    <td>{c.maturity_date}</td>         
                    {/*the `+` character preceding `parseFloat(...)` converts the string returned from `.toFixed(...)` to a number to remove trailing 0's*/}
                </tr>
                
                {hiddenRows.includes(c.id) && (
                <tr key={c.id + '_hidden'}>
                    {/* the below number dictates how wide the sub-table is; needs to be set to the number of features in the parent table */}
                    <td colspan='9'>
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th>Trade Date & Time</th>
                                {/* <th>Spread (bps)</th>
                                <th>ficc Curve (bps)</th> */}
                                <th>Yield (%)</th>
                                <th>Price (%)</th>
                                <th>Yield to Worst Date</th>
                                <th>Trade Amount (k)</th>
                                <th>Trade Type</th>
                            </tr>
                        </thead>
                        <tbody>{dispSingleTradeDetails(grouped, c.cusip)}</tbody>
                    </Table>
                    </td>
                </tr>
                )}</>
            )))
        } else if (isPricing || isRelatedLoading || !similarBondsSearchHasRun) {
            return (<tr style={{border: 'none'}}></tr>)
        } else {
            return (<tr style={{border: 'none'}}><br></br>The current criteria is too restrictive to find any similar bonds. Consider removing the S&P rating, purpose class, and / or description.</tr>)
        }
    }

    function drawTable(){
        return(
            <Table striped bordered>
            {noRelatedBondsFound? '':
            <thead>
                <tr>
                    <th>CUSIP</th>
                    <th>State</th>
                    <th>S&P</th>
                    <th>Description</th>
                    <th>Avg Yield (%)</th>
                    <th>Avg Price (%)</th>
                    {/* <th>Avg Spread (bps)</th> */}
                    <th>Coupon</th>
                    <th>Maturity Date</th>
                </tr>
            </thead>
            }
            <tbody>{dispTradesByCusip()}</tbody>
            </Table>
        )
    }

    const stateRef = React.createRef()
    const ratingRef = React.createRef()
    const purposeClassRef = React.createRef()

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId='formFile' className='mb-3'>
                    <Row><Col><Form.Label>Or enter your criteria for recent trades:</Form.Label></Col></Row>

                    <Row>
                        <Col>
                        <Form.Label class='font-weight-light' size='sm' for='cusip'>State: </Form.Label>
                            <Typeahead id='typeaheadState'
                                ref={stateRef}    // used to clear the value if it is not selected from the dropdown list
                                onChange={selected => {
                                    const ids = selected.map(({ id }) => id)
                                    if (ids.length > 0) { setRelatedDict({ 'state': ids }) }
                                    else { setRelatedDict({ 'state': undefined }) }
                                }}
                                selected = {relatedSearchVal.state !== undefined ? [findOptionById(statesList, relatedSearchVal.state)]:[]}
                                options={statesList}
                            />
                        </Col>
                        <Col>
                        <Form.Label class='font-weight-light' size='sm' for='cusip'>S&P Rating: </Form.Label>
                            <Typeahead id='typeaheadRating'
                                ref={ratingRef}    // used to clear the value if it is not selected from the dropdown list
                                onChange={selected => {
                                    const ids = selected.map(({ id }) => id)
                                    if (ids.length > 0){ setRelatedDict({ 'rating': ids }) }
                                    else { setRelatedDict({ 'rating': undefined }) }
                                }}
                                selected = {relatedSearchVal.rating !== undefined ? [findOptionById(ratingList, relatedSearchVal.rating)]:[]}
                                options={ratingList}
                            />
                        </Col>
                        <Col>
                        <Form.Label class='font-weight-light' size='sm' for='cusip'>Purpose Class: </Form.Label>
                            <Typeahead id='typeaheadPurposeClass'
                                ref={purposeClassRef}    // used to clear the value if it is not selected from the dropdown list
                                onChange={selected => {
                                    const ids = selected.map(({ id }) => id)
                                    if (ids.length > 0){ setRelatedDict({ 'purposeClass': ids }) } 
                                    else { setRelatedDict({ 'purposeClass': undefined }) }
                                }}
                                selected = {relatedSearchVal.purposeClass !== undefined ? [findOptionById(purposeClassList, relatedSearchVal.purposeClass)]:[]}
                                options={purposeClassList}
                            />
                        </Col>
                        <Col className='align-self-top'>
                        <p class='m-0'><br></br></p>
                        <ButtonGroup>
                            {radios.map((radio, idx) => (
                            <ToggleButton
                                key={idx}
                                id={`radio-${idx}`}
                                type='radio'
                                variant={idx % 2 ? 'outline-secondary' : 'outline-secondary'}
                                name='radio'
                                value={radio.value}
                                checked={radioValue === radio.value}
                                onChange={(e) => {setRadioValueAndUpdateRelatedDict(e.currentTarget.value)}}
                            >
                                {radio.name}
                            </ToggleButton>
                            ))}
                        </ButtonGroup>
                        </Col>
                        <Col>
                            {isRelatedLoading?<Button className='btn btn-primary my-3' disabled><Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true'/> Fetching Similars </Button>:<Button className='btn btn-primary my-3' type='submit'>Search</Button>}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                        <Form.Label class='font-weight-light' size='sm' for='cusip'>Maturity Date Range: </Form.Label>
                        <MultiRangeSlider
                            min={2025}
                            max={2125}
                            scale={1}
                            onChange={({ min, max }) => {setMaturityYear(min, max)}}    // need to put brackets around setMaturityYear otherwise the function is called immediately and causes an infinite loop
                        />
                        </Col>
                        {/* <Col>
                        <Form.Label class='font-weight-light' size='sm' for='cusip'>Calculation date range: </Form.Label>
                        <MultiRangeSlider
                            min={2025}
                            max={2125}
                            onChange={({ min, max }) => {setCalculationYear(min, max)}}    // need to put brackets around setCalculationYear otherwise the function is called immediately and causes an infinite loop
                        />
                        </Col> */}
                        <Col>
                            {/* <Form.Label class='font-weight-light' size='sm' for='cusip'>Coupon: </Form.Label>
                            <InputGroup> */}
                            {/* Change step size: https://vlad-ignatov.github.io/react-numeric-input/ */}
                            {/* <Form.Control placeholder='Coupon' type='number' step={0.01} min='0' name='coupon' value={relatedSearchVal.coupon} onChange={set('coupon')}/>
                            <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup> */}
                            <Form.Label class='font-weight-light' size='sm' for='cusip'>Coupon Range: </Form.Label>
                            <MultiRangeSlider
                                min={0}
                                max={1000}
                                scale={100}
                                onChange={({ min, max }) => {setCoupon(min, max)}}    // need to put brackets around setCoupon otherwise the function is called immediately and causes an infinite loop
                            />
                        </Col>
                        <Col>
                        <Form.Label class='font-weight-light' size='sm'>Minimum Trade Amount (thousands):</Form.Label>
                        <InputGroup>    
                            <InputGroup.Text>$ (k)</InputGroup.Text>
                        <Form.Control placeholder='Dollar Amount' type='number' min='0' name='amount' value={relatedSearchVal.amount} onChange={set('amount')}/>
                        </InputGroup>
                        </Col>
                        {/* <Col>
                        <Form.Label class='font-weight-light' size='sm' for='cusip'>Purpose sub class: </Form.Label>
                        <Typeahead id='typeaheadPurposeSubClass'
                            onChange={selected => {
                                const ids = selected.map(({ id }) => id)
                                if (ids.length > 0){
                                    setRelatedDict({ 'purposeSubClass': ids })
                                } else {
                                    setRelatedDict({ 'purposeSubClass': undefined })
                                }
                            }}
                            selected = {relatedSearchVal.purposeSubClass != undefined ? [findOptionById(subClassList, relatedSearchVal.purposeSubClass)]:[]}
                            options={subClassList}
                        />
                        </Col> */}
                        <Col>
                            <Form.Label class='font-weight-light' size='sm' for='cusip'>Description: </Form.Label>
                            <Form.Control type='text' placeholder='Description' name='desc' onChange={set('desc')} value={relatedSearchVal.desc} />
                        </Col>
                        <Col className='align-self-top'>
                        {/* Add empty break the button with the other fields */}
                        <p class='m-0'><br></br></p>
                        <ButtonGroup>
                            {issuerChoices.map((issuerChoice, idx) => (
                            <ToggleButton
                                key={idx}
                                id={`issuerChoice-${idx}`}
                                type='radio'
                                variant={idx % 2 ? 'outline-secondary' : 'outline-secondary'}
                                name='issuerChoice'
                                value={issuerChoice.value}
                                checked={issuerChoiceValue === issuerChoice.value}
                                onChange={(e) => {setIssuerChoiceValueAndUpdateRelatedDict(e.currentTarget.value)}}
                            >
                                {issuerChoice.name}
                            </ToggleButton>
                            ))}
                        </ButtonGroup>
                        </Col>
                        {/* Create extra dummy columns to keep everything aligned before the search bar
                        <Col></Col> */}
                    </Row>
                </Form.Group>
            </Form>
            {drawTable()}
    </div>
    )
}

    
export default (SimilarBonds)